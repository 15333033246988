.page {
    max-height: 100vh;
}

.screen {
    display: flex;
    flex-direction: column;
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00ffff;
    background: black;
    flex: 1;
    text-align: center;
}

.social {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #00ffff;
    background: black;
    text-align: center;
}

.text {
    font-family: monospace !important;
    font-size: 24px;
    margin-inline: 20px;
}

.a, a:link, a:visited {
    color: #00ffff;
    text-decoration: none;
}
